import axios from 'axios';

import { ICarerSuggestion } from '@/shared/model/carer-suggestion.model';

const baseApiUrl = 'api/carer-suggestions';
const baseSearchApiUrl = 'api/_search/carer-suggestions?query=';

export default class CarerSuggestionService {
  public findByTaskId(taskId: number): Promise<ICarerSuggestion[]> {
    return new Promise<ICarerSuggestion[]>((resolve, reject) => {
      axios
        .get(`api/carer-suggestions-by-task-id/${taskId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCarerSuggestionForCustomer(customerId: number): Promise<ICarerSuggestion> {
    return new Promise<ICarerSuggestion>((resolve, reject) => {
      axios
        .get(`api/carer-suggestions-by-customer-id/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public search(query): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseSearchApiUrl}${query}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<ICarerSuggestion> {
    return new Promise<ICarerSuggestion>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICarerSuggestion): Promise<ICarerSuggestion> {
    return new Promise<ICarerSuggestion>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICarerSuggestion): Promise<ICarerSuggestion> {
    return new Promise<ICarerSuggestion>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICarerSuggestion): Promise<ICarerSuggestion> {
    return new Promise<ICarerSuggestion>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
