import axios from 'axios';
import { ICustomer } from '@/shared/model/customer.model';

const baseApiUrl = 'api/customer-detail';

export default class CustomerDetailService {
  public retrieveData(customerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveFlags(customerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${customerId}/flags`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveNewPatient(customerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${customerId}/patients/new`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCoreData(customerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}-core/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateCore(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}-core/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public archiveCustomer(id: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/archive`, null)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public reactivateCustomer(id: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/reactivate`, null)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getPatientsDynamicFields(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}/dynamic-fields`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public raiseEvent(id: number, event: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/events/${event}/raise`, null)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
