import { Component, Inject, Prop, Provide, Watch } from 'vue-property-decorator';
import CarerDetailService from './carer-detail.service';
import { Carer, ICarer } from '@/shared/model/carer.model';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import TaskDataUtils from '@/shared/data/task-data-utils.service';
import RouterUtils from '@/shared/utils/router-utils.service';
import CareUtils from '@/shared/utils/care-utils.service';
import { Agency, IAgency } from '@/shared/model/agency.model';
import AgencyService from '@/entities/agency/agency.service';
import { Document, IDocument } from '@/shared/model/document.model';
import { EntityType } from '@/shared/model/enumerations/entity-type.model';
import DocumentService from '@/entities/document/document.service';
import { CareBeginType } from '@/shared/model/enumerations/care-begin-type.model';
import { CareDuration } from '@/shared/model/enumerations/care-duration.model';
import { CommunicationType } from '@/shared/model/enumerations/communication-type.model';
import { TransportVehicle } from '@/shared/model/enumerations/transport-vehicle.model';
import dayjs from 'dayjs';
import { ZONED_DATE_TIME_SERVER_FORMAT } from '@/shared/date/filters';
import { DocumentType } from '@/shared/model/enumerations/document-type.model';
import { DocumentStatus } from '@/shared/model/enumerations/document-status.model';
import CarerService from '@/entities/carer/carer.service';
import { ICareAssignment } from '@/shared/model/care-assignment.model';
import { Language } from '@/shared/model/enumerations/language.model';
import CarerSuggestionService from '@/entities/carer-suggestion/carer-suggestion.service';
import customer from '@/router/customer';
import { CarerSuggestion } from '@/shared/model/carer-suggestion.model';
import { Customer } from '@/shared/model/customer.model';

const validations: any = {
  carer: {
    healthInsuranceValidTo: {},
  },
};

@Component({
  methods: {
    customer() {
      return customer;
    },
  },
  validations,
})
export default class CarerDetailComponent extends mixins(JhiDataUtils, TaskDataUtils, CareUtils, RouterUtils) {
  @Prop() paramsForOverview;
  public carer: ICarer = new Carer();
  public careAssignmentYears = [];
  public isLoading = true;
  public isSaving = false;
  public currentLanguage = '';

  public agencies: IAgency[] = [];
  public agencyName = '';

  public documents: IDocument[] = [];
  public newDocument: IDocument = new Document();
  public documentTypeValues: string[] = Object.keys(DocumentType);
  public documentStatusValues: string[] = Object.keys(DocumentStatus);

  public isLoadingDocuments = true;

  public careBeginTypeValues: string[] = Object.keys(CareBeginType);
  public careDurationValues: string[] = Object.keys(CareDuration);
  public communicationTypeValues: string[] = Object.keys(CommunicationType);
  public transportVehicleValues: string[] = Object.keys(TransportVehicle);

  public languages: string[] = Object.keys(Language);
  public languageSkillEnums = [0, 1, 2, 3, 4, 5];

  public userTaskId = null;
  public suggestionForPrinting = null;
  public languageSkillForPrinting = '';
  public characterForPrinting = '';

  @Provide('agencyService') private agencyService = () => new AgencyService();
  public tabIndex = 0;
  @Inject('carerService') private carerService: () => CarerService;

  @Inject('carerDetailService')
  private carerDetailService: () => CarerDetailService;

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.carerId) {
        vm.retrieveCarerData(to.params.carerId);
        vm.retrieveCarerDocuments(to.params.carerId);
      }

      if (to.params.tabIndex) {
        vm.setTabIndex(to.params.tabIndex);
      } else {
        vm.setTabIndex(0);
      }

      vm.readTaskDataAddition(to.params.carerId);
    });
  }

  @Watch('paramsForOverview')
  onDataChanged(paramsForOverview, paramsForOverviewOld) {
    if (paramsForOverview.carerId) {
      this.retrieveCarerData(paramsForOverview.carerId);
      this.retrieveCarerDocuments(paramsForOverview.carerId);
    }

    if (paramsForOverview.tabIndex) {
      this.setTabIndex(paramsForOverview.tabIndex);
    } else {
      this.setTabIndex(0);
    }

    if (paramsForOverview.carerId) {
      this.readTaskDataAddition(paramsForOverview.carerId);
    }

    if (paramsForOverview.userTaskId) {
      this.userTaskId = paramsForOverview.userTaskId;
    }
  }

  public switchTab(tabIndex) {
    if (!this.paramsForOverview) {
      this.replace('CarerDetail', { carerId: this.carer.id, tabIndex: tabIndex });
    }
  }

  public archiveCarer() {
    this.carerService()
      .archiveCarer(this.carer.id)
      .then(param => {
        this.carer.archive = true;
        const message = this.$t('care4YouApp.carer.archived', { param: param.id });
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isLoading = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public reactivateCarer() {
    this.carerService()
      .reactivateCarer(this.carer.id)
      .then(param => {
        this.carer.archive = false;
        const message = this.$t('care4YouApp.carer.reactivated', { param: param.id });
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isLoading = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public readTaskDataAddition(carerId: number): void {
    const newDoc = new Document();
    newDoc.documentName = 'Neues Dokument';
    newDoc.parentKey = carerId;
    newDoc.parentType = EntityType.CARER;
    this.newDocument = newDoc;

    this.readDocumentTemplates(carerId);
  }

  public isLoadingAll(): boolean {
    return this.isLoadingDocuments || this.isLoadingTaskData;
  }

  public initialOpenAccordions: string[] = [];
  private openAccordions: string[] = [];

  public toggleAccordion(index: string): void {
    const ind = this.openAccordions.indexOf(index);
    if (ind >= 0) {
      this.openAccordions.splice(ind, 1);
    } else {
      this.openAccordions.push(index);
    }
  }

  public getToggleAccordion(index: string): boolean {
    return this.openAccordions.indexOf(index) >= 0;
  }

  public getInitialOpenAccordion(index: string): boolean {
    return this.initialOpenAccordions.indexOf(index) >= 0;
  }

  public convertDateTimeFromServer(date: Date): string {
    if (date && dayjs(date).isValid()) {
      return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }
    return null;
  }

  public updateZonedDateTimeField(index, field, event) {
    if (event.target.value) {
      const dat = dayjs(event.target.value, 'YYYY-MM-DDTHH:mm');
      const formatedDat = dat.format(ZONED_DATE_TIME_SERVER_FORMAT);
      this.carer.careAssignments[index][field] = formatedDat;
    } else {
      this.carer.careAssignments[index][field] = null;
    }
  }

  private setTabIndex(tabIndex): void {
    this.tabIndex = tabIndex;
  }

  created(): void {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );
  }

  public getDateFormatedWithDefault(val, def) {
    if (val) {
      return this.$options.filters.formatDate(val);
    }

    return def;
  }

  public getCareAssignmentsForYear(assignments: ICareAssignment[], year: number) {
    const filteredAssignments = assignments.filter(value => {
      const dat = dayjs(value.arrivalAt, 'YYYY-MM-DD');
      return dat.year() == year || (year == 0 && !dat.year());
    });
    return filteredAssignments;
  }

  private retrieveCarerData(carerId): void {
    this.isLoading = true;
    this.carerDetailService()
      .retrieveData(carerId)
      .then(param => {
        this.carer = param;
        this.deriveCarerSuggestionForPrinting(this.carer);
        this.deriveCareAssignmentYears();

        this.agencyService()
          .retrieve()
          .then(res => {
            this.agencies = res.data;

            this.agencyName = '';

            for (let i = 0; i < this.agencies.length; i++) {
              if (this.agencies[i].id === this.carer.agency.id) {
                this.agencyName = this.agencies[i].name;
              }
            }

            this.$emit('carerloaded', this.carer);
            this.isLoading = false;
          });
      })
      .catch(error => {
        this.isLoading = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  private deriveCarerSuggestionForPrinting(carer: ICarer) {
    const suggestion = new CarerSuggestion();
    suggestion.desiredSalary = 0;
    suggestion.travelCosts = 0;
    suggestion.totalCosts = 0;
    suggestion.carer = carer;
    suggestion.customer = new Customer();
    suggestion.customer.agency = new Agency();
    this.languageSkillForPrinting = this.$t('care4YouApp.languageSkill.levels.' + this.getGermanLanguageSkill(suggestion)).toString();
    this.characterForPrinting = suggestion?.carer?.character;
    this.suggestionForPrinting = suggestion;
  }

  private getGermanLanguageSkill(instance: CarerSuggestion): number {
    if (instance.carer != null && instance.carer.languageSkills != null) {
      const filteredSkills = instance.carer.languageSkills.filter(value => {
        return value.language.valueOf() == 'GERMAN';
      });
      if (filteredSkills.length > 0) {
        return filteredSkills[0].level;
      }
    }
    return 0;
  }

  private deriveCareAssignmentYears() {
    const years = [];
    let lastYear = -1;
    let lastEntry = undefined;
    let hasNullArrival = false;
    this.carer.careAssignments.forEach(value => {
      if (value.arrivalAt) {
        const curDateYear = dayjs(value.arrivalAt, 'YYYY-MM-DD').year();
        if (lastYear != curDateYear) {
          if (lastEntry != undefined) {
            years.push(lastEntry);
          }
          lastEntry = {
            year: curDateYear,
            days: 0,
          };
          lastYear = curDateYear;
        }
        lastEntry.days += value.workingDays;
      } else {
        hasNullArrival = true;
      }
    });
    if (lastEntry) {
      years.push(lastEntry);
    }
    if (hasNullArrival) {
      years.push({ year: 0, days: 0 });
    }

    this.careAssignmentYears = years;
  }

  public isWord(docType: string): boolean {
    return docType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || docType === 'application/msword';
  }

  public isExcel(docType: string): boolean {
    return (
      docType === 'application/vnd.openxmlformats-officedocument.excelprocessingml.document' ||
      docType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  public isImage(docType: string): boolean {
    return ['image/jpeg', 'image/png', 'image/bmp'].includes(docType);
  }

  public isPdf(docType: string): boolean {
    return docType === 'application/pdf';
  }

  public isOtherFile(docType: string): boolean {
    return !this.isExcel(docType) && !this.isWord(docType) && !this.isPdf(docType);
  }

  @Provide('documentService') private documentService = () => new DocumentService();

  private retrieveCarerDocuments(carerId): void {
    this.isLoadingDocuments = true;
    this.documentService()
      .findByEntityTypeAndId(EntityType.CARER, carerId)
      .then(param => {
        this.documents = param;
        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public setChangedAt(document: IDocument): void {
    // @ts-ignore
    document.statusChangedAt = dayjs(new Date()).format(ZONED_DATE_TIME_SERVER_FORMAT);
  }

  @Provide('carerSuggestionService') private carerSuggestionService = () => new CarerSuggestionService();

  public readDocumentTemplates(carerId: number): void {
    this.isLoadingDocuments = true;
    this.documentService()
      .getDocumentsByCarerId(carerId)
      .then(res => {
        this.documents = res;
        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public addNewFile(): void {
    if (!this.checkDocumentTypeAndContentTypeMatch(this.newDocument)) {
      const message = this.$t('care4YouApp.carer.idHasToBeWord');
      return (this.$root as any).$bvToast.toast(message.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Fehler',
        variant: 'danger',
        solid: true,
        autoHideDelay: 5000,
      });
    }
    // @ts-ignore
    this.newDocument.createdAt = dayjs(new Date()).format(ZONED_DATE_TIME_SERVER_FORMAT);

    this.isLoadingDocuments = true;
    this.documentService()
      .create(this.newDocument)
      .then(res => {
        this.newDocument = res;
        this.documents.push(this.newDocument);

        const newDoc = new Document();
        newDoc.documentName = 'Neues Dokument';
        newDoc.parentKey = this.carer.id;
        newDoc.parentType = EntityType.CARER;
        this.newDocument = newDoc;
        (<any>this).$refs['document_file_new'].value = null;

        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public validDataForNewDoc(): boolean {
    return (
      this.newDocument.documentName != null &&
      this.newDocument.documentName !== '' &&
      this.newDocument.documentBinaries != null &&
      this.newDocument.documentBinaries !== '' &&
      this.newDocument.documentBinariesContentType != null &&
      this.newDocument.documentBinariesContentType !== ''
    );
  }

  public checkDocumentTypeAndContentTypeMatch(doc: IDocument) {
    if (doc.documentType === 'ID') {
      return this.isImage(doc.documentBinariesContentType);
    }

    return true;
  }

  public safeFile(document: Document) {
    if (!this.checkDocumentTypeAndContentTypeMatch(document)) {
      const message = this.$t('care4YouApp.carer.idHasToBeImage');
      return (this.$root as any).$bvToast.toast(message.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Fehler',
        variant: 'danger',
        solid: true,
        autoHideDelay: 5000,
      });
    }
    this.documentService()
      .update(document)
      .then(res => {
        this.newDocument = res;

        const message = this.$t('care4YouApp.carer.updated', { param: res.parentKey });
        return (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });

        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public deleteFile(document: Document) {
    this.isLoadingDocuments = true;
    this.documentService()
      .delete(document.id)
      .then(res => {
        const message = this.$t('care4YouApp.document.deleted', { param: document.id });
        this.removeDocumentFromList(document.id);
        (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });

        this.isLoadingDocuments = false;
      })
      .catch(error => {
        this.isLoadingDocuments = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  protected removeDocumentFromList(id): void {
    this.documents = this.documents.filter(value => value.id != id);
  }

  public save(): void {
    this.isSaving = true;
    this.taskId = this.userTaskId;

    this.carerService()
      .updateHealthInsurance(this.carer)
      .then(param => {
        this.isSaving = false;

        this.$router.replace({ name: 'Postbox' });

        const message = this.$t('care4YouApp.carer.updated', { param: param.id });
        return (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public generatePdf(suggestion) {
    // @ts-ignore
    this.$refs.realSuggestionPdf.generatePdf(suggestion);
  }

  public getTranslatedLanguage(languageCode) {
    return this.$t(`care4YouApp.Language.${languageCode}`);
  }

  public getTranslatedLevel(level) {
    return this.$t(`care4YouApp.languageSkill.levels.${level}`);
  }

  public healthInsuranceNotSaveable() {
    return this.isSaving || this.isLoading || !this.carer.healthInsuranceValidTo;
  }

  public healthInsuranceNotCancelable() {
    return this.isSaving || this.isLoading;
  }

  public cancelHealthInsurance(): void {
    this.isSaving = true;

    this.carerService()
      .cancelHealthInsurance(this.carer)
      .then(param => {
        this.isSaving = false;

        this.previousState();

        const message = this.$t('care4YouApp.carer.cancelHealthInsurance');
        return (this.$root as any).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'info',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public ausweis(): boolean {
    for (const currDoc of this.documents) {
      if (currDoc.documentType === 'ID') {
        return true;
      }
    }

    return false;
  }

  public healthInsuranceUserTask(): boolean {
    return this.paramsForOverview && this.userTaskId;
  }

  public getAccept(doc: IDocument) {
    if (doc.documentType == null || doc.documentType !== 'ID') {
      return '*';
    } else {
      return '.jpg,.png,.webp,.bmp';
    }
  }
}
