import { Component, Inject, Vue } from 'vue-property-decorator';
import AlertService from '@/shared/alert/alert.service';
import { IBpmUserTask } from '@/shared/model/bpm-user-task.model';
import { BpmTaskState } from '@/shared/model/enumerations/bpm-task-state.model';
import { IBpmUserTaskExtended } from '@/shared/model/bpm-user-task-extended.model';

/**
 * An utility service.
 */
@Component
export default class PostboxUtils extends Vue {
  @Inject('alertService') protected alertService: () => AlertService;

  public getTaskDescriptionPart1(desc: string, lang: string): string {
    desc = desc.replace('\\n', '\n');
    const newLinePos = desc.indexOf('\n');
    if (newLinePos >= 0) {
      desc = desc.substring(0, newLinePos);
    }
    const semicolonPos = desc.indexOf(';');
    if (semicolonPos >= 0) {
      const addition = desc.substring(semicolonPos + 1);
      return this.$t(desc.substring(0, semicolonPos), lang, { param: addition }).toString();
    }

    return this.$t(desc, lang).toString();
  }

  public getTaskDescriptionPart2(desc: string, lang: string): string {
    desc = desc.replace('\\n', '\n');
    const newLinePos = desc.indexOf('\n');
    if (newLinePos >= 0 && newLinePos + 1 < desc.length) {
      return desc.substring(newLinePos + 1);
    }

    return null;
  }

  public isTaskFinished(task: IBpmUserTask): boolean {
    return BpmTaskState.FINISHED == task.state;
  }

  public isTaskEditable(task: IBpmUserTaskExtended): boolean {
    return task.canEdit == true && task.userTask.state == BpmTaskState.OPEN;
  }

  public isTaskDataEditable(task: IBpmUserTask): boolean {
    return (task.taskType == 'customer-task' || task.taskType == 'task-reminder') && task.state == BpmTaskState.OPEN;
  }
}
