import { Component, Inject, Prop, Provide, Watch } from 'vue-property-decorator';
import QuestionnaireService from '../../questionnaire/questionnaire.service';
import AgencyService from '@/entities/agency/agency.service';
import { IPatientCareAttribute, PatientCareAttribute } from '@/shared/model/patient-care-attribute.model';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { ValueType } from '@/shared/model/enumerations/value-type.model';
import RouterUtils from '@/shared/utils/router-utils.service';
import AlertService from '@/shared/alert/alert.service';
import CareUtils from '@/shared/utils/care-utils.service';

const validations: any = {};

@Component({
  validations,
})
export default class FragebogenComponent extends mixins(JhiDataUtils, CareUtils, RouterUtils) {
  @Prop({ default: true }) readonlyMode: boolean;
  @Prop() agencyId: number;
  @Prop() fragebogenNummer: string;
  @Prop() patientCareAttributes: PatientCareAttribute[];
  public attributes: PatientCareAttribute[] = [];
  public agencyName = '';
  public agencyStreet = '';
  public agencyHouseNumber = '';
  public agencyPostcode = '';
  public agencyCity = '';
  public agencyWebsite = '';
  public agencyMail = '';
  public agencyPhone = '';
  public agencyFax = '';
  public agencyLogoImageType = '';
  public agencyLogoImageData = '';
  public isSaving = false;
  public isLoading = true;
  public isLoadingAgencyData = true;
  private isLoadedViaCustomerDetails = false;
  public currentLanguage = '';
  public title = 'title';
  @Inject('alertService') protected alertService: () => AlertService;
  private ansprechpartnerEhepartner = false;
  private ansprechpartnerSchwager = false;
  private ansprechpartnerSohn = false;
  private ansprechpartnerSonstige = '';
  private infoText = null;

  private mandatoryFields: string[] = [];

  @Watch('fragebogenNummer', { deep: true, immediate: true })
  onDataChangedFragebogenNummer(value: string, oldValue: string) {
    if (value != null && this) {
      this.readQuestionnaireByFragebogenNummer(value);
    }
  }

  @Watch('patientCareAttributes', { deep: true, immediate: true })
  onDataChangedPatientCareAttributes(value: PatientCareAttribute[], oldValue: PatientCareAttribute[]) {
    if (value != null && this) {
      this.attributes = value;
      this.transferAttributesToLocalVariables();
      this.isLoadedViaCustomerDetails = true;
      this.isLoading = false;
    }
  }

  @Watch('agencyId', { deep: true, immediate: true })
  onDataChangedAgencyId(value: number, oldValue: number) {
    if (value != null && this) {
      this.readAgencyDataForQuestionnaire(value);
    }
  }

  created(): void {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );
    this.setMandatoryFields();
  }

  public hasFax() {
    return this.getFax() != null && this.getFax() != '';
  }

  public isLoadingAll(): boolean {
    return this.isLoading || this.isLoadingAgencyData;
  }

  public isSavingAll(): boolean {
    return this.isSaving;
  }

  public previousState(): void {
    this.$router.go(-1);
  }

  public submitFragebogen() {
    if (!this.checkMandatoryFields()) {
      const message = this.$t('care4YouApp.fragebogen.messages.mandatoryFieldsNeeded');
      (this.$root as any).$bvToast.toast(message.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Fehler',
        variant: 'danger',
        solid: true,
        autoHideDelay: 5000,
      });
      return;
    }
    this.isSaving = true;
    this.questionnaireService()
      .updateByFragebogenNummer(this.fragebogenNummer, this.prepareAttributes())
      .then(param => {
        this.isSaving = false;
        const message = this.$t('care4YouApp.questionnaire.saved');
        this.infoText = message.toString();
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public readAgencyDataForQuestionnaire(agencyId: number): void {
    this.isLoadingAgencyData = true;
    this.questionnaireService()
      .getAgencyDataForQuestionnaire(agencyId)
      .then(res => {
        try {
          this.agencyName = this.getProp(res, 'agencyName');
          this.agencyStreet = this.getProp(res, 'agencyStreet');
          this.agencyHouseNumber = this.getProp(res, 'agencyHouseNumber');
          this.agencyPostcode = this.getProp(res, 'agencyPostcode');
          this.agencyCity = this.getProp(res, 'agencyCity');
          this.agencyWebsite = this.getProp(res, 'agencyWebsite');
          this.agencyMail = this.getProp(res, 'agencyMail');
          this.agencyPhone = this.getProp(res, 'agencyPhone');
          this.agencyFax = this.getProp(res, 'agencyFax');
          this.agencyLogoImageType = this.getProp(res, 'agencyLogoImageType');
          this.agencyLogoImageData = this.getProp(res, 'agencyLogoImageData');
        } catch (e) {
          this.alertService().showError(this, e.message);
        }
        this.isLoadingAgencyData = false;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
        this.isLoadingAgencyData = false;
      });
  }

  public readQuestionnaireByFragebogenNummer(fragebogenNummer: string): void {
    this.isLoading = true;
    this.isLoadingAgencyData = true;
    this.questionnaireService()
      .findByFragebogenNummer(fragebogenNummer)
      .then(res => {
        try {
          this.attributes = this.transformPatientCareAttributes(this.getProp(res, 'attributes'));
          this.transferAttributesToLocalVariables();
          this.agencyName = this.getProp(res, 'agencyName');
          this.agencyStreet = this.getProp(res, 'agencyStreet');
          this.agencyHouseNumber = this.getProp(res, 'agencyHouseNumber');
          this.agencyPostcode = this.getProp(res, 'agencyPostcode');
          this.agencyCity = this.getProp(res, 'agencyCity');
          this.agencyWebsite = this.getProp(res, 'agencyWebsite');
          this.agencyMail = this.getProp(res, 'agencyMail');
          this.agencyPhone = this.getProp(res, 'agencyPhone');
          this.agencyFax = this.getProp(res, 'agencyFax');
          this.agencyLogoImageType = this.getProp(res, 'agencyLogoImageType');
          this.agencyLogoImageData = this.getProp(res, 'agencyLogoImageData');
        } catch (e) {
          this.alertService().showError(this, e.message);
        }
        this.isLoading = false;
        this.isLoadingAgencyData = false;
      })
      .catch(error => {
        if (error.response?.status == 404) {
          const message = this.$t('care4YouApp.questionnaire.notExisting');
          this.infoText = message.toString();
        } else {
          this.alertService().showHttpError(this, error.response);
        }
        this.isLoading = false;
        this.isLoadingAgencyData = false;
      });
  }

  private transferAttributesToLocalVariables() {
    let ansprechpartner = this.getQuestion(this.attributes, this.QUESTION_ANSPRECHPARTNER)?.value;
    if (ansprechpartner == 'Tochter/Sohn') {
      this.ansprechpartnerSohn = true;
      this.ansprechpartnerSchwager = false;
      this.ansprechpartnerEhepartner = false;
      this.ansprechpartnerSonstige = '';
    } else if (ansprechpartner == 'Schwiegertochter/-sohn') {
      this.ansprechpartnerSohn = false;
      this.ansprechpartnerSchwager = true;
      this.ansprechpartnerEhepartner = false;
      this.ansprechpartnerSonstige = '';
    } else if (ansprechpartner == 'Ehepartner') {
      this.ansprechpartnerSohn = false;
      this.ansprechpartnerSchwager = false;
      this.ansprechpartnerEhepartner = true;
      this.ansprechpartnerSonstige = '';
    } else {
      this.ansprechpartnerSohn = false;
      this.ansprechpartnerSchwager = false;
      this.ansprechpartnerEhepartner = false;
      this.ansprechpartnerSonstige = ansprechpartner;
    }
  }

  public getQuestion(attributes: IPatientCareAttribute[], attr: string) {
    return attributes.find((value, index) => {
      return value.attribute.name == attr;
    });
  }

  public getProp(obj, prop: string) {
    if (obj == null) {
      return null;
    }
    if (obj.hasOwnProperty(prop)) {
      return obj[prop];
    }

    return null;
  }

  public convertTimeRangeFromServer(timerange: string, part: number) {
    if (timerange == null) {
      return '';
    }

    const parts = timerange.split('-');
    if (parts.length > part) {
      return parts[part];
    }

    return '';
  }

  public updateTimeRangeField(attribute, event, part: number) {
    const parts = attribute.value == null ? [] : attribute.value.split('-');
    parts[part] = event;
    attribute.value = parts.join('-');
  }

  // UI methods
  public getAnschrift() {
    return this.agencyName + ' - ' + this.agencyStreet + ' ' + this.agencyHouseNumber + ' - ' + this.agencyPostcode + ' ' + this.agencyCity;
  }

  public getFax() {
    return this.agencyFax;
  }

  private setMandatoryFields() {
    this.mandatoryFields.push(this.QUESTION_WUNSCHDATUM1);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_NAME);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_VORNAME);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_STRASSE);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_HAUSNUMMER);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_PLZ);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_ORT);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_TELEFON);
    this.mandatoryFields.push(this.QUESTION_ANSPRECHPARTNER_MAIL);
    this.mandatoryFields.push(this.QUESTION_PATIENT_NAME);
    this.mandatoryFields.push(this.QUESTION_PATIENT_VORNAME);
    this.mandatoryFields.push(this.QUESTION_PATIENT_STRASSE);
    this.mandatoryFields.push(this.QUESTION_PATIENT_HAUSNUMMER);
    this.mandatoryFields.push(this.QUESTION_PATIENT_PLZ);
    this.mandatoryFields.push(this.QUESTION_PATIENT_ORT);
    this.mandatoryFields.push(this.QUESTION_PATIENT_GEBURTSDATUM);
    this.mandatoryFields.push(this.QUESTION_PATIENT_GROESSE);
    this.mandatoryFields.push(this.QUESTION_PATIENT_GEWICHT);
    this.mandatoryFields.push(this.QUESTION_PATIENT_KRANKENKASSE);
    this.mandatoryFields.push(this.QUESTION_PFLEGEGRAD);
    this.mandatoryFields.push(this.QUESTION_HAUSHALT_KOCHEN);
    this.mandatoryFields.push(this.QUESTION_HAUSHALT_HAUSTIERE);
    this.mandatoryFields.push(this.QUESTION_DATENSCHUTZ_AKZEPTIERT);
  }

  public validateValue(field) {
    if (this.readonlyMode == true || this.isLoadedViaCustomerDetails) {
      return null;
    }
    if (this.mandatoryFields.indexOf(field) != -1) {
      if (this.checkField(field)) {
        return false;
      }
    }
    return null;
  }

  public getLabelStyleByValidateValue(field) {
    if (this.validateValue(field) === false) {
      return 'color: #e51c23; font-weight: bold;';
    }
    return '';
  }

  public getMail() {
    return this.agencyMail;
  }

  private checkField(currField) {
    const question = this.getQuestion(this.attributes, currField);
    if (question != null) {
      if (question.value == null || question.value == '') {
        return true;
      }
    }
    return false;
  }

  private checkMandatoryFields() {
    return (
      this.mandatoryFields.filter((currField, index) => {
        return this.checkField(currField);
      }).length == 0
    );
  }

  // UI methods end
  @Provide('agencyService') private agencyService = () => new AgencyService();

  @Provide('questionnaireService') private questionnaireService = () => new QuestionnaireService();

  private prepareAttributes(): IPatientCareAttribute[] {
    const attributesCopy = JSON.parse(JSON.stringify(this.attributes)) as typeof this.attributes;

    if (attributesCopy.length > 0) {
      attributesCopy.forEach(function (pca: PatientCareAttribute) {
        if (pca.attribute.valueType !== ValueType.STRING) {
          pca.value = JSON.stringify(pca.value);
        }
      });
    }

    return attributesCopy;
  }

  private transformPatientCareAttributes(attributes: IPatientCareAttribute[]): IPatientCareAttribute[] {
    attributes.forEach(function (pca: PatientCareAttribute) {
      pca.value = JSON.parse(pca.value);
    });

    return attributes;
  }

  private onAnsprechpartnerChange(ansprechpartnerField: string) {
    var newValue = null;
    if (ansprechpartnerField == 'ehepartner') {
      newValue = this.ansprechpartnerEhepartner == true ? 'Ehepartner' : '';
      this.ansprechpartnerSchwager = false;
      this.ansprechpartnerSohn = false;
      this.ansprechpartnerSonstige = '';
    } else if (ansprechpartnerField == 'schwager') {
      newValue = this.ansprechpartnerSchwager == true ? 'Schwiegertochter/-sohn' : '';
      this.ansprechpartnerEhepartner = false;
      this.ansprechpartnerSohn = false;
      this.ansprechpartnerSonstige = '';
    } else if (ansprechpartnerField == 'kind') {
      newValue = this.ansprechpartnerSohn == true ? 'Tochter/Sohn' : '';
      this.ansprechpartnerEhepartner = false;
      this.ansprechpartnerSchwager = false;
      this.ansprechpartnerSonstige = '';
    } else if (ansprechpartnerField == 'sonstige') {
      newValue = this.ansprechpartnerSonstige;
      this.ansprechpartnerSchwager = false;
      this.ansprechpartnerSohn = false;
      this.ansprechpartnerEhepartner = false;
    }

    this.getQuestion(this.attributes, this.QUESTION_ANSPRECHPARTNER).value = newValue;
  }

  public isSaveButtonVisible() {
    return this.readonlyMode == false && this.fragebogenNummer != null;
  }

  private getObligatorySign() {
    return this.readonlyMode == true || this.isLoadedViaCustomerDetails ? '' : '*';
  }
}
